import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "../js/shipping-service";
import PrinterDetails from "../../common/printer-details";
import Utility from "../../../shared/utility.js";
// import VueBarcodeReader from "vue-barcode-reader";
// import 'vue-barcode-reader/dist/vue-barcode-reader.css';
export default {
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlash: true,
    backSlashParent: true,
    auditId: "",
    shippingAuditDetails: [],
    scanBoxIDAudit: "",
    auditItems: [],
    boxAuditDialog: false,
    boxDetails: [],
    shipToAddress: false,
    returnAddress: false,
    shipToAddressDetails: "",
    returnAddressDetails: "",
    scanOutboundRefNum: "",
    scanReturnRefNum: "",
    enterAuditResolutionSummary: "",
    secondaryName: EncryptUtility.localStorageDecrypt('secondaryName'),
    additionalValidation: [],
    validationsChanged: [],
    shipAddressPrinter: true,
    returnAddressPrinter: true,
    canAudit: false,
    canTake: false,
    CSSStatusClass: "",
    CSSResultClass: "",
    boxCSSStatusClass: "",
    boxCSSResultClass: "",
    CSSAuditResultClass: "",
    CSSAuditStatusClass: "",
    serializedBoxScan: [],
    validationResultDetails: [],
    validationResultTbl: false,
    boxScanFields: false,
    checkBtnDisabled: true,
    submitObj: [],
    labelSNText: "",
    SNJson: [],
    submitResult: [],
    xmlData: "",
    isFormValid: false,
    questionList: [],
    max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length must be 50 characters or less"],
    boxIDRule: [(v) => !!v || "Field is required", (v) => /^\d+$/.test(v) || "Only numbers are allowed"],
    errorMsg: "",
    outRefChecked: false,
    retRefChecked: false,
    headersAudit: [
      { text: "Box ID", value: "Box_ID", class: "primary customwhite--text" },
      { text: "Project/Business", value: "projBusiness", class: "primary customwhite--text" },
      { text: "Tot Qty In Box", value: "Tot Qty", class: "primary customwhite--text" },
      { text: "Shipper", value: "Shipper", class: "primary customwhite--text" },
      { text: "Auditor", value: "Auditor", class: "primary customwhite--text" },
      { text: "Box Audit Status", value: "AuditResult", class: "primary customwhite--text" },
      { text: "Box Audit Result", value: "AuditStatus", class: "primary customwhite--text" },
    ],
    validationResultHeaders: [
      { text: "ID", value: "value", class: "primary customwhite--text" },
      { text: "Validation Result", value: "ok", class: "primary customwhite--text" },
      { text: "Result", value: "comment", class: "primary customwhite--text" },
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    let actionData = await AddFavourite.getParentPageAction(this.userId, this.subPageUrl);
    this.actionList = actionData?.actionList;
    if (this.$route.params.audit_id !== undefined) {
      this.auditId = atob(this.$route.params.audit_id);
      this.getShippingAuditHeader();
      this.getShippingAuditBox();
    } else {
      this.$router.push(`/shipping-audit-queue`);
    }
  },
  watch: {
    scanOutboundRefNum(newValue) {
      this.scanOutboundRefNum = newValue.trim();
    },
    scanReturnRefNum(newValue) {
      this.scanReturnRefNum = newValue.trim();
    },
  },
  mounted() {},
  methods: {
    //Reset function
    resetFunction() {
      this.auditId = "";
      this.shippingAuditDetails = [];
      this.scanBoxIDAudit = "";
      this.auditItems = [];
      this.boxAuditDialog = false;
      this.boxDetails = [];
      this.shipToAddress = false;
      this.returnAddress = false;
      this.shipToAddressDetails = "";
      this.returnAddressDetails = "";
      this.scanOutboundRefNum = "";
      this.scanReturnRefNum = "";
      this.enterAuditResolutionSummary = "";
      this.secondaryName = "";
      this.additionalValidation = [];
      this.validationsChanged = [];
      this.shipAddressPrinter = true;
      this.returnAddressPrinter = true;
      this.canAudit = false;
      this.canTake = false;
      this.CSSStatusClass = "";
      this.CSSResultClass = "";
      this.boxCSSStatusClass = "";
      this.boxCSSResultClass = "";
      this.CSSAuditResultClass = "";
      this.CSSAuditStatusClass = "";
      this.serializedBoxScan = [];
      this.validationResultDetails = [];
      this.validationResultTbl = false;
      this.boxScanFields = false;
      this.checkBtnDisabled = true;
      this.labelSNText = "";
      this.submitResult = [];
      this.xmlData = "";
      this.isFormValid = false;
      this.questionList = [];
      this.outRefChecked = false;
      this.retRefChecked = false;
    },
    //Audit Box Details
    getShippingAuditBox() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("sh/get_shipping_audit_box?user_id=" + this.userId + "&audit_id=" + this.auditId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.auditItems = responseData.Result;
            let statusClass = this.auditItems[0].CSS_Config.filter((obj) => obj.status === this.auditItems[0].AuditStatus);
            this.CSSAuditStatusClass = statusClass[0].class;
            let resultClass = this.auditItems[0].CSS_Config.filter((obj) => obj.status === this.auditItems[0].AuditResult);
            this.CSSAuditResultClass = resultClass[0].class;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.$router.push(`/shipping-audit-queue`);
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Audit Box Header Details
    getShippingAuditHeader() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("sh/get_shipping_audit_header?user_id=" + this.userId + "&audit_id=" + this.auditId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            const newArr = responseData.Result.map((obj) => {
              return {
                ...obj,
                Ship_Date: Utility.convertESTToLocal(obj.Ship_Date),
              };
            });
            this.shippingAuditDetails = newArr;
            let statusClass = this.shippingAuditDetails[0].CSS_Config.filter((obj) => obj.status === this.shippingAuditDetails[0].Audit_Status);
            this.CSSStatusClass = statusClass[0].class;
            let resultClass = this.shippingAuditDetails[0].CSS_Config.filter((obj) => obj.status === this.shippingAuditDetails[0].Audit_Result);
            this.CSSResultClass = resultClass[0].class;
            if (this.shippingAuditDetails[0].canaudit !== null && this.shippingAuditDetails[0].canaudit !== undefined) {
              this.canAudit = this.shippingAuditDetails[0].canaudit;
            } else {
              this.canAudit = false;
            }
            if (this.shippingAuditDetails[0].cantake !== null && this.shippingAuditDetails[0].cantake !== undefined) {
              this.canTake = this.shippingAuditDetails[0].cantake;
            } else {
              this.canTake = false;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.$router.push(`/shipping-audit-queue`);
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Audit Box Header Details
    getShippingAuditHeaderWithoutLoader() {
      this.axios
        .get("sh/get_shipping_audit_header?user_id=" + this.userId + "&audit_id=" + this.auditId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.shippingAuditDetails = responseData.Result;
            let statusClass = this.shippingAuditDetails[0].CSS_Config.filter((obj) => obj.status === this.shippingAuditDetails[0].Audit_Status);
            this.CSSStatusClass = statusClass[0].class;
            let resultClass = this.shippingAuditDetails[0].CSS_Config.filter((obj) => obj.status === this.shippingAuditDetails[0].Audit_Result);
            this.CSSResultClass = resultClass[0].class;
            if (this.shippingAuditDetails[0].canaudit !== null && this.shippingAuditDetails[0].canaudit !== undefined) {
              this.canAudit = this.shippingAuditDetails[0].canaudit;
            } else {
              this.canAudit = false;
            }
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          this.$router.push(`/shipping-audit-queue`);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Handshake button when clicked
    async handshakeCall() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let obj = {
        UserId: parseInt(this.userId),
        ship_audit_id: parseInt(this.auditId),
      };
      this.axios
        .post("sh/shipping_audit_takeownership", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.getShippingAuditHeaderWithoutLoader();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Box Details
    getShippingAuditBoxHeader() {
      if (this.$refs.boxSearchForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .get(
            "sh/get_shipping_audit_box_header?user_id=" + this.userId + "&ship_audit_id=" + this.auditId + "&box_id=" + parseInt(this.scanBoxIDAudit)
          )
          .then((response) => {
            if (response.status == 200) {
              let responseData = JSON.parse(response.data.body.message);
              const newArr = responseData.Result.map((obj) => {
                return {
                  ...obj,
                  Ship_Date: Utility.convertESTToLocal(obj.Ship_Date),
                };
              });
              this.boxDetails = newArr;
              let statusClass = this.shippingAuditDetails[0].CSS_Config.filter((obj) => obj.status === this.boxDetails[0].Audit_Status);
              this.boxCSSStatusClass = statusClass[0].class;
              let resultClass = this.shippingAuditDetails[0].CSS_Config.filter((obj) => obj.status === this.boxDetails[0].Audit_Result);
              this.boxCSSResultClass = resultClass[0].class;
              if (this.boxDetails[0].SN_Count > 0) {
                this.boxScanFields = true;
              } else {
                this.boxScanFields = false;
              }
              if (this.boxDetails[0].ship_address !== null && this.boxDetails[0].ship_address !== undefined) {
                this.shipToAddressDetails = this.boxDetails[0].ship_address.replaceAll("||", "\n");
              }
              if (this.boxDetails[0].return_address !== null && this.boxDetails[0].return_address !== undefined) {
                this.returnAddressDetails = this.boxDetails[0].return_address.replaceAll("||", "\n");
              } else {
                this.returnAddressDetails = "No Return Address";
              }
              if (this.boxDetails !== null) {
                if (this.boxDetails[0].outbound_label !== null) {
                  this.shipAddressPrinter = false;
                } else {
                  this.shipAddressPrinter = true;
                }
                if (this.boxDetails[0].return_label !== null) {
                  this.returnAddressPrinter = false;
                } else {
                  this.returnAddressPrinter = true;
                }
              }
              if (this.boxDetails[0].lbl_sn_text !== null && this.boxDetails[0].lbl_sn_text !== undefined) {
                let labelText = this.boxDetails[0].lbl_sn_text.toUpperCase();
                this.labelSNText = labelText.replaceAll("||", "<br />");
              }
              if (this.boxDetails.length !== 0) {
                this.boxAuditDialog = true;
                this.getAdditionalValidations();
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            this.scanBoxIDAudit = "";
            this.$refs.boxSearchForm.resetValidation();
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Get Additional Validations
    async getAdditionalValidations() {
      let response = await shippingService.getAdditionalValidations("get", this.userId, parseInt(this.scanBoxIDAudit));
      if (response.message !== "NA" && response !== 400) {
        this.additionalValidation = response;
      }
    },
    //Outbound Reference Number check
    outBoundRefNumCheck() {
      if (this.scanOutboundRefNum == this.boxDetails[0].tracking_num) {
        this.outRefChecked = true;
      } else {
        this.outRefChecked = false;
      }
    },
    //Return Reference Number check
    returnRefNumCheck() {
      if (this.scanReturnRefNum == this.boxDetails[0].rtn_tracking_num) {
        this.retRefChecked = true;
      } else {
        this.retRefChecked = false;
      }
    },
    //Submit call for Box Audit
    submitBoxAudit() {
      if (this.scanOutboundRefNum == this.boxDetails[0].tracking_num && this.scanReturnRefNum == this.boxDetails[0].rtn_tracking_num) {
        if (this.shipToAddress) {
          if (this.returnAddress) {
            this.questionList = [];
            for (var i = 0; i < this.additionalValidation.length; i++) {
              if (this.validationsChanged.length > 0) {
                for (var j = 0; j < this.validationsChanged.length; j++) {
                  if (this.additionalValidation[i].Quest == this.validationsChanged[j]) {
                    this.questionList.push({ qid: this.additionalValidation[i].qid, Quest: this.additionalValidation[i].Quest, Ans: "Yes" });
                  }
                }
              } else {
                this.questionList.push({ qid: this.additionalValidation[i].qid, Quest: this.additionalValidation[i].Quest, Ans: "No" });
              }
            }
            this.questionList.push({ qid: this.additionalValidation.length + 1, Quest: "Ship-To address verified", Ans: "Yes" });
            this.questionList.push({ qid: this.additionalValidation.length + 2, Quest: "Return address verified", Ans: "Yes" });
            this.questionList.push({ qid: this.additionalValidation.length + 3, Quest: "Outbound reference number is correct", Ans: "Yes" });
            this.questionList.push({ qid: this.additionalValidation.length + 4, Quest: "Return reference number is correct", Ans: "Yes" });
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            if (this.boxScanFields) {
              this.submitObj = [];
              this.SNJson = [];
              this.SNJson=JSON.parse(JSON.stringify(this.serializedBoxScan?.split("\n")));
              this.submitObj = {
                user_id: parseInt(this.userId),
                audit_box_id: parseInt(this.scanBoxIDAudit),
                resolve_comment: this.enterAuditResolutionSummary,
                ansJSON: JSON.stringify(this.questionList),
                SN_JSON: JSON.stringify(this.SNJson),
              };
            } else {
              this.submitObj = [];
              this.SNJson = [];
              this.SNJson=JSON.parse(JSON.stringify(this.serializedBoxScan?.split("\n")));       
              this.submitObj = {
                user_id: parseInt(this.userId),
                audit_box_id: parseInt(this.scanBoxIDAudit),
                resolve_comment: this.enterAuditResolutionSummary,
                ansJSON: JSON.stringify(this.questionList),
                SN_JSON: JSON.stringify(this.SNJson),
              };
            }
            this.axios
              .post("sh/shipping_audit_queue_submit", this.submitObj)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  let data = JSON.parse(response.data.body.message);
                  this.submitResult = data.Result;
                  if (this.submitResult[0].status == "P") {
                    if (this.submitResult[0].outbound_label !== null && this.submitResult[0].outbound_label !== "") {
                      PrinterDetails.printLabelData(this.submitResult[0].outbound_label, this.secondaryName);
                    }
                    if (this.submitResult[0].return_label !== null && this.submitResult[0].return_label !== "") {
                      PrinterDetails.printLabelData(this.submitResult[0].return_label, this.secondaryName);
                    }
                  }
                  this.boxAuditDialog = false;
                  this.getShippingAuditHeader();
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: "Success",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.boxAuditDialog = false;
                  this.$router.go(-1);
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                this.boxAuditDialog = false;
                this.$router.go(-1);
              })
              .catch((error) => {
                if (!error.response.data.message.includes("\\")) {
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: Utility.apiError(error),
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  this.errorMsg = "";
                  this.errorMsg = JSON.parse(error.response.data.message);
                  if (this.errorMsg !== undefined && this.errorMsg !== null) {
                    let snFailMsg = this.errorMsg.Result[0].sn_fail == "" ? [] : JSON.parse(this.errorMsg.Result[0].sn_fail);
                    let ansFailMsg = this.errorMsg.Result[0].ans_fail == "" ? [] : JSON.parse(this.errorMsg.Result[0].ans_fail);
                    if (snFailMsg.length !== 0 && ansFailMsg.length !== 0) {
                      if (!snFailMsg[0].ok && !ansFailMsg[0].ok) {
                        let Alert = {
                          type: "error",
                          isShow: true,
                          message: "Serialized Units and answers to the Validations have been failed",
                        };
                        this.$store.commit("ConfigModule/Alert", Alert);
                      }
                    } else if (snFailMsg.length !== 0) {
                      if (!snFailMsg[0].ok) {
                        let Alert = {
                          type: "error",
                          isShow: true,
                          message: "Serialized Units have been failed",
                        };
                        this.$store.commit("ConfigModule/Alert", Alert);
                      }
                    } else if (ansFailMsg.length !== 0) {
                      if (!ansFailMsg[0].ok) {
                        let Alert = {
                          type: "error",
                          isShow: true,
                          message: "Answers to the Validations have been failed",
                        };
                        this.$store.commit("ConfigModule/Alert", Alert);
                      }
                    }
                  }
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                this.errorMessage = error.message;
                this.boxAuditDialog = false;
                this.$router.go(-1);
              });
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "Return Address is not verified",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Ship To Address is not verified",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Scanned Reference Numbers are not correct",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Clear Audit User Details
    clearAuditUserDetails() {
      this.shipToAddress = false;
      this.returnAddress = false;
      this.scanOutboundRefNum = "";
      this.scanReturnRefNum = "";
      this.shipToAddressDetails = "";
      this.returnAddressDetails = "";
      this.enterAuditResolutionSummary = "";
      this.additionalValidation = [];
      this.validationsChanged = [];
      this.boxAuditDialog = false;
      this.scanBoxIDAudit = "";
      this.$refs.boxSearchForm.resetValidation();
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("sh/shipping_audit_clearuser?user_id=" + this.userId + "&audit_box_id=" + this.auditId)
        .then((response) => {
          if (response.status == 200) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.getShippingAuditHeader();
            this.getShippingAuditBox();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Ship to address print functionality
    printShipAddrSelect() {
      if (this.boxDetails[0].outbound_label !== undefined && this.boxDetails[0].outbound_label !== "") {
        PrinterDetails.printLabelData(this.boxDetails[0].outbound_label, this.secondaryName);
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .get("sh/shipping_audit_updateprintercounter?user_key=" + this.userId + "&audit_box_id=" + parseInt(this.scanBoxIDAudit))
          .then((response) => {
            if (response.status == 200) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Ship To label is not available",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Return address print functionality
    printReturnAddrSelect() {
      if (this.boxDetails[0].return_label !== undefined && this.boxDetails[0].return_label !== "") {
        PrinterDetails.printLabelData(this.boxDetails[0].return_label, this.secondaryName);
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.axios
          .get("sh/shipping_audit_updateprintercounter?user_key=" + this.userId + "&audit_box_id=" + parseInt(this.scanBoxIDAudit))
          .then((response) => {
            if (response.status == 200) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              var Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Return Address label is not available",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Check button click event
    checkSerializedUnits() {
      if (this.serializedBoxScan == [] && this.serializedBoxScan == null) {
        var Alert = {
          type: "error",
          isShow: true,
          message: "Please enter any Serial Numbers to check",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        let json = JSON.stringify(this.serializedBoxScan?.split("\n"));
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        let obj = {
          user_key: parseInt(this.userId),
          audit_box_id: parseInt(this.scanBoxIDAudit),
          SN_JSON: json,
        };
        this.axios
          .post("sh/shipping_audit_box_validate", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              let responseData = JSON.parse(response.data.body.message);
              this.validationResultDetails = responseData.Result;
              this.boxScanFields = true;
              this.validationResultTbl = true;
              this.checkBtnDisabled = true;
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            this.boxScanFields = false;
            this.validationResultTbl = false;
            this.checkBtnDisabled = false;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: "Check returned no results. Please submit serial list again",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Check button enable on text enter
    checkBtnEnable() {
      if (this.serializedBoxScan !== null && this.serializedBoxScan.length !== 0 && this.serializedBoxScan !== "") {
        this.checkBtnDisabled = false;
      } else {
        this.checkBtnDisabled = true;
      }
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
